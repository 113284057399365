<template>
  <div>
    <headertop Inv="2"></headertop>

    <div class="payMain">
      <div class="left">
        <div class="box">
          <div class="title">
            <span>配送费方式：{{ orderInfo.deliveryType == 2 ? '仓库自提' : orderInfo.deliveryType == 1 ? '配送站自提' : '送货上门' }}</span>
          </div>
          <div class="main" v-if="orderInfo.deliveryType == 2">
            <div class="desc">负责人：{{ orderInfo.storeSelfPoint.linkName }} </div>
            <div class="desc">联系方式：{{ orderInfo.storeSelfPoint.linkPhone }}</div>
            <div class="desc">地址：{{ orderInfo.storeSelfPoint.address }}</div>
            <div class="desc">工作时间：{{ orderInfo.storeSelfPoint.workTime }}</div>
          </div>

          <div class="main" v-if="orderInfo.deliveryType == 0">
            <div class="desc">地址标签：{{ orderInfo.addressTagName }}</div>
            <div class="desc">联系人：{{ orderInfo.linkName }}</div>
            <div class="desc">联系方式：{{ orderInfo.linkPhone }}</div>
            <div class="desc">地点：{{ orderInfo.detailAddress }}</div>
          </div>

          <div class="main" >
            <div class="desc">支付方式：{{  orderInfo.payType == 0 ? "paypal" :  orderInfo.payType == 1 ?"预付款":orderInfo.payType == 2?'货到付款':orderInfo.payType == 3?'转账':orderInfo.payType == 4?'支付宝':orderInfo.payType == 5?'微信支付':'信用卡' }}</div>
          </div>
        </div>

        <div class="box">
          <div class="title">查看物品</div>
          <div class="main">
            <!-- <div class="titles">
              <div class="left">
                <img :src="$store.state.logo" alt="" />
                <span>{{ $store.state.name }}</span>
              </div>
            </div> -->

            <div class="list" v-for="(item, index) in goodslist" :key="index">
              <el-image style="width: 150px" :src="item.goodPic" fit="contain "></el-image>
              <div class="text">
                <div class="name">
                  <div class="names">{{ item.goodName }}</div>
                </div>
                <div class="price">${{ item.itemPrice }}</div>
                <div class="guige">
                  <div>
                    <span>规格：{{ item.unitZnName }}</span>
                  </div>
                  <div v-if="item.proValue">
                    <span>属性：{{ item.proValue }}</span>
                  </div>
                  <div class="addCar">x {{ item.goodNum }}</div>
                </div>
              </div>
            </div>
            
            <!-- <div class="typeUp">{{ type == 1 ? "送货上门" : "自提" }}</div> -->
          </div>
        </div>
      </div>
      <div class="name"></div>
      <div class="right">
        <div class="price">
          <span>金额</span>
        </div>
        <div style="margin-bottom:10px;font-weight: bold;">
          <span style="margin-right: 20px;min-width: 60px;display: inline-block;">总金额</span><span style="color: red;">${{
            orderInfo.allRealPrice }}</span>
        </div>
        <div style="margin-bottom:10px;font-weight: bold;">
          <span style="margin-right: 20px;min-width: 60px;display: inline-block;">折扣金额</span><span style="color: red;">${{
            orderInfo.allDiscountPrice }}</span>
        </div>
        <div style="margin-bottom:10px;font-weight: bold;">
          <span style="margin-right: 20px;min-width: 60px;display: inline-block;">实付金额</span><span style="color: red;">${{
            orderInfo.allPrice }}</span>
        </div>


        <div class="btns">
          <span style="margin-right: 20px;" @click="backCar">返回购物车</span>
          <span @click="sureSubmit()">确认提交</span>
        </div>
      </div>
    </div>


    <el-dialog title="确认提交" :visible.sync="dialogVisible" width="30%">
      <!-- sandbox -->
      <!-- env="production" -->
      <PayPal v-if="orderInfo.payType == 0" :amount="amount" currency="AUD" :client="credentials" :env="env"
        :button-style="buttonStyle" @payment-authorized="paymentAuthorized" @payment-completed="paymentCompleted"
        @payment-cancelled="paymentCancelled">
      </PayPal>
      <div v-else style="font-size: 27px;font-weight: bold;letter-spacing: 3px;  text-align: center;">提交成功</div>
      <!-- <PayPal :amount="amount" currency="AUD" :client="credentials" env="sandbox" :button-style="buttonStyle"
        @payment-authorized="paymentAuthorized" @payment-completed="paymentCompleted"
        @payment-cancelled="paymentCancelled">
      </PayPal> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeBack">关 闭</el-button>
      </span>
    </el-dialog>
    <bottom></bottom>
  </div>
</template>

<script>
import headertop from "../components/header.vue";

import PayPal from "vue-paypal-checkout";
import bottom from "../components/bottom.vue";

import {
  cartcartNextGet,
  ordercreateOrder,
  orderpaySuccessOrder,
  ordercreateCartOrder
} from "@/request/api.js";

export default {
  name: "home",
  components: {
    bottom,
    headertop,
    PayPal,
  },
  data() {
    return {
      dialogVisible: false,
      type: "1",
      amount: "1",
      credentials: {
        sandbox:
          "AYU3Ok_40gXENzZb5FOLrAgN57uMLp9MOaOFmRNIrPoBb0fVWDqKOQomeRAr-m5uBvaj3mDFMoa6e_Ny",
        production:
          "AdUzSCCq1rNlSYM9ca2q6M75BNW9moJADOU1VHl631tsdJPhDY-n8JdMimbpLp8iHfJ-jax64i9DqqqK",
      },
      env: 'sandbox',

      buttonStyle: {
        label: "pay",
        size: "responsive",
        shape: "pill",
        color: "blue",
      },
      goodslist: [],
      address: "",
      selfPickUp: [],
      radio: "",
      orderNum: "",
      orderInfo: {},
      orderId: ''
    };
  },
  created(e) {
    this.seeInfo()
    // this.getData();
  },
  mounted() { },
  methods: {
    getPaySet() {
      this.$api.onlineconfig().then(res => {
        console.log('支付的參數')
        console.log(res)
        this.credentials.sandbox = res.data.payPalConfig.sandClientId
        this.credentials.production = res.data.payPalConfig.proClientId
        this.env = res.data.payPalConfig.env
      })
    },
    seeInfo() {
      this.$api.cartcartNextGet().then(res => {
        this.goodslist = res.data.details
        this.orderInfo = res.data
        this.amount = res.data.allPrice;
        this.getPaySet()
      })
    },
    toAdd() {
      this.$router.push({
        path: `/my?Inv=2`,
      });
    },
    createOrder() {
      var deliveryType = 0;
      if (this.type == 1) {
        deliveryType = 0;
      } else {
        deliveryType = 2;
      }
      let query = {
        deliveryType,
        addressId: this.address ? this.address.id : "",
        liftId: this.radio,
      };

      ordercreateOrder(query).then((res) => {
        console.log(res);
        console.log("订单创建成功");
        this.orderNum = res.data.orderNum;
        this.amount = res.data.allPrice;
        this.dialogVisible = true;
      });
    },
    paymentAuthorized(data) {
      // 授权完成的回调，可以拿到订单id
      console.log("授权完成的回调");
      console.log(data);
    },
    closeBack() {
      this.dialogVisible = false
      this.$router.go(-1)
    },

    paymentCompleted(data) {
      console.log('支付成功的回调')
      console.log(data)
      //支付成功
      let query = {
        orderNum: this.orderInfo.orderNum,
        paymentId: data.id
      };
      orderpaySuccessOrder(query).then((res) => {
        this.$notify({
          title: "成功",
          message: "支付成功",
          type: "success",
        });
        this.$router.push({
          path: `/`,
        });
      });
    },
    sureSubmit() {
      ordercreateCartOrder().then(res => {
        this.dialogVisible = true
      })
    },

    paymentCancelled(data) {
      console.log('取消交易的回调')
      console.log(data)
      // 用户取消交易的回调
      this.$router.go(-1)

    },
    backCar() {
      this.$router.push({
        path: `/car`,
      });
    }

  },
};
</script>

<style>
.el-radio {
  display: flex;
  margin-bottom: 15px;
}
</style>
<style lang="scss" scoped>
.paypal-button {
  position: relative;
  z-index: 0;
}

.btns {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: inline-block;
    width: 120px;
    height: 45px;
    background-color: #ff6464;
    color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 15px;
  }
}

.payMain {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .left {
    width: 65%;

    .box {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 30px;
      border: 1px solid #e1e1e1;

      .title {
        font-size: 16px;
        border-bottom: 1px solid #e1e1e1;
        padding: 15px 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .main {
        padding: 10px;
        box-sizing: border-box;

        .titles {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;

          .left {
            display: flex;
            align-items: center;
            font-size: 15px;

            img {
              width: 22px;
              margin-right: 8px;
            }
          }
        }

        .desc {
          font-size: 15px;
          margin-bottom: 5px;
        }

        .typeUp {
          text-align: right;
          padding-right: 30px;
          font-size: 16px;
          font-weight: bold;
          margin-top: 10px;
        }

        .list {
          position: relative;
          display: flex;
          padding-right: 30px;
          cursor: pointer;
          padding-bottom: 20px;

          .text {
            width: 100%;
            padding-left: 20px;
          }

          .price {
            font-size: 20px;
            color: #e70012;
            font-weight: bold;
          }

          .guige {
            margin-top: 5px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .gui {
              margin-left: 10px;
              font-size: 16px;
            }

            .addCar {
              color: #e70012;
              font-weight: bold;
              font-size: 16px;
            }
          }

          .name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 20px;

            .names {
              font-size: 18px;
              font-weight: bold;
              letter-spacing: 1px;

              color: #2c2a2a;
            }

            .del {
              width: 30px;
            }
          }

          .xuanzhe {
            width: 20px;
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
          }

          .goodsImg {
            width: 100px;
          }
        }
      }
    }
  }

  .btnPay {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: #009cde;
    color: #fff;
    cursor: pointer;
  }

  .right {
    width: 33%;
    border: 1px solid #e1e1e1;
    height: 300px;
    box-sizing: border-box;
    padding: 20px 10px;

    .price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      margin-bottom: 20px;
      font-size: 18px;
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 20px;
    }

    .desc {
      text-align: center;
      margin-top: 20px;
      font-size: 14px;
      color: #959595;
    }
  }
}
</style>
